<template>
    <div class="services">


        <p class="text-4xl text-gray-900 font-extralight dark:text-white text">Услуги</p>

        <div class="block_section">
            <div v-if="isWideScreen">
                <div class="block_section_three">
                    <div class="image_section_tree" v-for="(item, index) in limitedImageItems" :key="index"
                        @click="openModal(item)">
                        <img :src="item.path" alt="Image">
                        <span class="povid">{{ item.text }}</span>
                        <br>
                        <span class="button">Подробнее</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="block_section_one">
                    <div class="image_section_one" v-for="(item, index) in limitedImageItems" :key="index"
                        @click="openModal(item)">
                        <img :src="item.path" alt="Image">
                        <span class="povid">{{ item.text }}</span>
                        <br>
                        <span class="button">Подробнее</span>

                    </div>
                </div>
            </div>
            <Modal v-if="modalOpen" :item="selectedItem" @close="closeModal" />
        </div>
    </div>
    <Footer />
</template>

<script>
import Modal from '@/components/Modal.vue';
import Footer from '@/components/Footer.vue';


export default {
    name: "Animator",
    components: {
        Modal,
        Footer
    },
    data() {
        return {
            imageItems: [
                { path: '../services/1o.png', text: 'Аквагрим', description: 'Сказочные перевоплощения! <br> Сделать детский праздник ярче и веселей можно благодаря одной из самых популярных услуг — аквагриму от «Kids Party». За считанные минуты мы превратим ваших гостей в супергероев, забавных зверьков или мультяшных персонажей. Всего пару взмахов кистью и на лице появятся профессиональный грим. Ребята будут в восторге, теперь можно от души дурачиться и на полную катушку веселиться! А представьте, какие яркие и забавные получатся фотографии на память, где ребенок будет похож на своего любимого героя из мультфильма или сказки.' },
                { path: '../services/2o.png', text: 'Candy bar', description: 'Сладкий уголок на празднике тоже должен быть ярким и соответствовать тематике праздника. Посуда, конфеты, печенье, торт и другие сладости – сделать яркое оформление для дня рождения реально! Ребята придут в восторг от красивых сладостей в виде сказочных и других персонажей.' },
                { path: '../services/3o.png', text: 'Фото и видеосъемка', description: 'Так хочется запомнить детей маленькими, не упустить ни одного сладкого момента, ни одного праздника и восторга ребенка. С помощью наших видеооператоров это легко! Мы снимем весь день рождения или другую детскую вечеринку, качественно смонтируем и создадим настоящий документальный фильм! Каждый кадр — произведение искусства, каждое мгновение — навсегда останется с вами.' },
                { path: '../services/4o.png', text: 'Сладкая вата', description: 'Сахарная вата - это необычное лакомство, которое сделает ваш праздник по настоящему сладким, веселым и незабываемым. Эмоции, которые гости получат при поедании сладкой ваты не сравнить ни с чем. А аромат, который наполнит любое помещение, сведет с ума даже взрослого человека. Гости тут же побегут на поиски этого замечательного лакомства и не отойдут ни на шаг от нашего волшебника и шеф-повара по совместительству.' },
                { path: '../services/5o.png', text: 'Оформление', description: 'Детские праздники – это особый день для маленьких именинников и их родителей! Команда «Kids party» не понаслышке знает, как важно красивое украшение места проведения праздника. Сказочное оформление детского праздника создает волшебную атмосферу, помогает воплотить фантазию в мечту и удивить гостей праздника. Не знаете, какое оформление детского дня рождения лучше? Наша команда поделится знаниями и опытом в организации сказочных праздников для детей любого возраста.' },
                { path: '../services/6o.png', text: 'Beauty bar', description: 'Для маленьких стильных модников и модниц!<br> Мы создадим 1000 и 1 образ для именинницы и ее приглашенных гостей. Это не просто бьюти-бар, а настоящая лаборатория красоты, где исполняются мечты по мановению волшебной палочки. Сделаем для маленьких модниц нежный макияж настоящей принцессы, добавим немного блестящих аппликаций на щечки девочкам и крутые татушки мальчишкам!' },

            ],
            screenWidth: window.innerWidth,
            modalOpen: false,
            selectedItem: {}
        }
    },
    computed: {
        limitedImageItems() {
            return this.imageItems.slice(0, 9);
        },
        isWideScreen() {
            return this.screenWidth >= 800;
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth);
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
        openModal(item) {
            this.selectedItem = item;
            this.modalOpen = true;
        },
        closeModal() {
            this.modalOpen = false;
        }
    }
};
</script>

<style scoped>
.button {
    position: relative;
    bottom: 24%;
    -webkit-position: relative;
    -webkit-bottom: 24%;
    color: #000;
    font-size: 15px;
    border: 1px solid white;
    border-radius: 30px;
    background-color: white;
    padding: 15px;
    cursor: pointer;

}

.text {
    text-align: center;
    margin: 60px 0px;
}

.block_section {
    text-align: center;
    max-width: 1200px;
    margin: auto;
}

.block_section_three {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    /* Измените это значение по вашему желанию */
}

.block_section_one {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.image_section_tree,
.image_section_one {
    text-align: center;
    margin-bottom: 20px;
}

.image_section_one img {
    border: 0px solid;
    border-radius: 30px;
    padding: 10px;

}

.image_section_tree {
    padding: 10px;
}

.image_section_tree img {
    border: 0px solid;
    border-radius: 30px;

}

.image_section_tree span {}

.image_section_one span {}

.povid {
    position: relative;
    bottom: 28%;
    -webkit-position: relative;
    -webkit-bottom: 28%;
    color: white;
    font-size: 22px;
    font-weight: 800;
}

.services {
    max-width: 1600px;
    margin: auto;
    background-color: white;
}

@media (max-width: 800px) {
    .image_section_one img {
        width: 400px;
    }

    .text {
        margin-top: 0px;
    }
}
</style>