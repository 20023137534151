<template>
  <div class="menu" v-if="isMobile">
    <Slide :closeOnNavigation="true">
      <router-link to="/">Главная</router-link>
      <router-link to="/animator">Аниматоры</router-link>
      <router-link to="/shows">Шоу-программы</router-link>
      <router-link to="/service">Услуги</router-link>
    </Slide>
    <div class="menu_icon">
      <div class="text">
        <router-link to="/">Меню</router-link>
      </div>
      <div class="icon">
        <a href="https://www.instagram.com/kidsparty.uz/"><img src="../src/img/icon/instagram.png" alt="" srcset=""></a>
        <a href="https://t.me/kidsparty_uz"><img src="../src/img/icon/telegram.png" alt="" srcset=""></a>
      </div>
    </div>
  </div>

  <nav v-if="!isMobile">
    <router-link to="/">Главная</router-link>
    <router-link to="/animator">Аниматоры</router-link>
    <router-link to="/shows">Шоу-программы</router-link>
    <router-link to="/service">Услуги</router-link>
    <div class="socials">
      <a href="https://www.instagram.com/kidsparty.uz/"><img src="../src/img/icon/instagram.png" alt="" srcset=""></a>
      <a href="https://t.me/kidsparty_uz"><img src="../src/img/icon/telegram.png" alt="" srcset=""></a>
    </div>
  </nav>
  <router-view />




  <div data-dial-init class="fixed end-6 bottom-6 group">
    <div id="speed-dial-menu-default" class="flex flex-col items-center hidden mb-4 space-y-2">
      <button type="button" data-tooltip-target="tooltip-print" data-tooltip-placement="left"
        class="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
        <a href="https://t.me/kidsparty_uz"><img src="../src/img/icon/telegram.png" alt="" srcset=""></a>
        <span class="sr-only">Telegram</span>
      </button>
      <div id="tooltip-print" role="tooltip"
        class="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Telegram
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
      <button type="button" data-tooltip-target="tooltip-download" data-tooltip-placement="left"
        class="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
        <a href="https://www.instagram.com/kidsparty.uz/"><img src="../src/img/icon/inst.png" alt="" srcset=""></a>
        <span class="sr-only">Instagram</span>
      </button>
      <div id="tooltip-download" role="tooltip"
        class="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Instagram
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
      <button type="button" data-tooltip-target="tooltip-copy" data-tooltip-placement="left"
        class="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 dark:hover:text-white shadow-sm dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
        <a href="tel:+998974118909"><img src="../src/img/icon/phone.png" alt="" srcset=""></a>

        <span class="sr-only">Phone</span>
      </button>
      <div id="tooltip-copy" role="tooltip"
        class="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Phone
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </div>
    <button type="button" data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default"
      aria-expanded="false"
      class="flex items-center justify-center text-white bg-white rounded-full w-14 h-14 hover:bg-white dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
      <img src="../src/img/icon/phone.png" alt="" srcset="">
      <span class="sr-only">Open actions menu</span>
    </button>
  </div>


</template>

<style scoped>
.text {
  margin-left: 65px;
  font-size: 25px;
}

.text a {
  color: #000 !important;
}

.menu_icon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon a {
  margin: 0px 5px;
}

.icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: #fff;

}

.menu a {
  color: #fff;
  white-space: nowrap;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  background-color: #fff;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  margin: 0px 5px;
}

nav a.router-link-exact-active {
  color: rgb(126 58 242);
}

nav img {
  width: 40px;
}

.socials {
  display: flex;
}

</style>


<script>
import 'flowbite';

import { Slide } from 'vue3-burger-menu'
export default {
  components: {
    Slide // Register your component
  },
  data() {
    return {
      isMobile: false
    };
  },
  mounted() {
    // Initialize isMobile on component mount
    this.checkScreenWidth();

    // Listen for window resize events
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeDestroy() {
    // Remove window resize event listener on component destruction
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.isMobile = window.innerWidth < 600; // Check if screen width is less than 430px
    }
  }
}

</script>