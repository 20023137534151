<template>
  <div class="carusel">
    <div>
      <carousel :items-to-show="1" :autoplay=2000 :wrapAround=true>
        <slide class="df" v-for="(image, index) in images" :key="index">
          <img :src="image.src" :alt="image.alt">
          <span class="text">{{ image.text }}</span>
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
  name: 'Slider',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      isMobile: false,
      images: [
        { src: '/show/1o.png', alt: 'Image 1', text: 'Фокусник' },
        { src: '/show/2o.png', alt: 'Image 2', text: 'Научное шоу' },
        { src: '/show/3o.png', alt: 'Image 3', text: 'Шоу мыльных пузырей' },
        { src: '/show/4o.png', alt: 'Image 4', text: 'Тесла шоу' },
        { src: '/show/5o.png', alt: 'Image 5', text: 'Бумажное шоу' },
        { src: '/show/6o.png', alt: 'Image 2', text: 'Неоновое шоу' },
        { src: '/show/7o.png', alt: 'Image 3', text: 'Фольга шоу' },
        { src: '/show/8o.png', alt: 'Image 4', text: 'Пенная вечеринка' },
        { src: '/show/9o.png', alt: 'Image 5', text: 'Твистинг' },
        { src: '/show/10o.png', alt: 'Image 2', text: 'Квесты' },
        { src: '/show/12o.png', alt: 'Image 4', text: 'Мастер-классы' },
        { src: '/show/13o.png', alt: 'Image 5', text: 'Спектакль' },
      ],
    };
  }
}
</script>


<style scoped>
.text {
  color: white;
  font-size: 25px !important;
}

.df {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.carusel {
  max-width: 1600px;
  margin: auto;
}

.carusel img {
  width: 500px;
  border: 0px solid;
  border-radius: 30px
}

@media (max-width: 600px) {
  .carusel img {
    padding: 10px;
  }
}
</style>
