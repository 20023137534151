<template>
    <div class="services">


        <p class="text-4xl text-gray-900 font-extralight dark:text-white text">Шоу-программы</p>

        <div class="block_section">
            <div v-if="isWideScreen">
                <div class="block_section_three">
                    <div class="image_section_tree" v-for="(item, index) in limitedImageItems" :key="index"
                        @click="openModal(item)">
                        <img :src="item.path" alt="Image">
                        <span class="povid">{{ item.text }}</span>
                        <br>
                        <span class="button">Подробнее</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="block_section_one">
                    <div class="image_section_one" v-for="(item, index) in limitedImageItems" :key="index"
                        @click="openModal(item)">
                        <img :src="item.path" alt="Image">
                        <span class="povid">{{ item.text }}</span>
                        <br>
                        <span class="button">Подробнее</span>

                    </div>
                </div>
            </div>
            <Modal v-if="modalOpen" :item="selectedItem" @close="closeModal" />
        </div>
    </div>
    <Footer />
</template>

<script>
import Modal from '@/components/Modal.vue';
import Footer from '@/components/Footer.vue';


export default {
    name: "Animator",
    components: {
        Modal,
        Footer
    },
    data() {
        return {
            imageItems: [
                { path: '../show/1o.png', text: 'Фокусник', description: 'Наш фокусник не желает раскрывать секреты своего мастерства и держит в строжайшей тайне ход программы. Мы еле уговорили его намекнуть, чего ожидать ребятам. Обещают чтение тайных мыслей, открытие магического ларца, снежный шторм и пламенные цветы — ох, как интересно!' },
                { path: '../show/2o.png', text: 'Научное шоу', description: 'Когда научные исследования кажутся невероятно сложными и скучными, на помощь спешит наш сумасшедший профессор! Он покажет детям фантастические фокусы, которые имеют вполне научное обоснование, расскажет, про туманные пузыри и покажет, как кипятить газировку без огня — это новый взгляд на науку.' },
                { path: '../show/3o.png', text: 'Шоу мыльных пузырей', description: 'Команда «Kids Party» подготовила новую программу для тех, кто готов удивляться, веселиться и восхищаться! Только представьте себе дождик из мыльных пузырей или пузырьки, наполненные дымком, а еще лучше — гигантский пузырь, внутрь которого можно погрузиться от кончиков пальцев до макушки.' },
                { path: '../show/4o.png', text: 'Тесла шоу', description: 'Мы подготовили авторскую программу с увлекательными и захватывающими экспериментами. Узнайте, как приручить молнию и управлять электрическим зарядом на расстоянии!' },
                { path: '../show/5o.png', text: 'Бумажное шоу', description: 'Представьте, что в воздухе кружат тысячи крошечных бумажных ленточек, конфетти и снежинок — так начинается праздник с чистого листа, а точнее со 20 кг  чистых листов шелковистой белоснежной, серебристой или яркой цветной бумаги! «Kids party» представляет обновленную программу бумажного шоу на детский праздник, кстати, взрослым тоже разрешен вход — бумажная фантасмагория не оставит равнодушным ни малышей, ни стариков!' },
                { path: '../show/6o.png', text: 'Неоновое шоу', description: 'Если Вы проводите день рождение ребенка в помещении и хотите удивить своих гостей чем-то совершенно новым, необычным и креативным, неоновая шоу программа — это именно то, что Вам нужно.' },
                { path: '../show/7o.png', text: 'Фольга шоу', description: 'Фольгированное шоу, блестящее шоу, серебряное бумажное шоу, металлизированная бумага — это шоу называют по-разному, но суть остается неизменной — это невероятно красиво!' },
                { path: '../show/8o.png', text: 'Пенная вечеринка', description: 'Музыка, игры, конкурсы и угощения — отличный праздник, но чего-то не хватает? А мы знаем! Добавьте немного пены, и все будет cool! Сотни, нет, тысячи пенных пузырьков наполнят помещение, будут переливаться радужными гранями, отражая мерцание светомузыки, — это не просто красиво, а невероятно! Любой праздник благодаря пенной вечеринке станет особенным, в восторге будут и малыши, и дети постарше. Да, что там! Даже взрослые не смогут устоять, чтобы не окунуться в воздушную нежную пену.' },
                { path: '../show/9o.png', text: 'Твистинг', description: 'Твистинг — это профессиональное моделирование из воздушных шаров. Наверняка всем из вас знакомы фигуры из длинных разноцветных шаров, которые приводят детей в полный восторг, а взрослые просто никак не могут понять, как можно из простого шарика – колбаски скрутить собачку, зайчика или лягушку. Вот это скручивание и есть моделирование из воздушных шаров, или твистинг.' },
                { path: '../show/10o.png', text: 'Квесты', description: 'Квесты для детей – это уникальный жанр развлечения, который позволяет малышам погрузиться в захватывающий мир приключений и загадок. Детские квесты – это не просто игра, это способ обучения в формате развлечения, где дети развивают навыки командной работы, критического мышления и проблемного решения.' },
                { path: '../show/12o.png', text: 'Мастер-классы', description: 'Если возникли сложности с идеей подарка малышу на день рождения или по иному торжественному поводу, лучшее решение – заказать мастер-класс. Компания проведет красочные и оригинальные уроки прикладного мастерства, превратив процесс обучения в яркий карнавал, незабываемое событие, каковым и должно быть настоящее творчество.' },
                { path: '../show/13o.png', text: 'Спектакль', description: 'Выездной интерактивный спектакль погрузит каждого ребенка в сказку . Дети учатся уважать и понимать различные культуры. Театр обучает детей жизненным урокам, которые могут помочь им в будущем.' },

            ],
            screenWidth: window.innerWidth,
            modalOpen: false,
            selectedItem: {}
        }
    },
    computed: {
        limitedImageItems() {
            return this.imageItems.slice(0, 13);
        },
        isWideScreen() {
            return this.screenWidth >= 800;
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth);
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
        openModal(item) {
            this.selectedItem = item;
            this.modalOpen = true;
        },
        closeModal() {
            this.modalOpen = false;
        }
    }
};
</script>

<style scoped>
.button {
    position: relative;
    bottom: 24%;
    -webkit-position: relative;
    -webkit-bottom: 24%;
    color: #000;
    font-size: 15px;
    border: 1px solid white;
    border-radius: 30px;
    background-color: white;
    padding: 15px;
    cursor: pointer;

}

.text {
    text-align: center;
    margin: 60px 0px;
}

.block_section {
    text-align: center;
    max-width: 1200px;
    margin: auto;
}

.block_section_three {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    /* Измените это значение по вашему желанию */
}

.block_section_one {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.image_section_tree,
.image_section_one {
    text-align: center;
    margin-bottom: 20px;
}

.image_section_one img {
    border: 0px solid;
    border-radius: 30px;
    padding: 10px;

}

.image_section_tree {
    padding: 10px;
}

.image_section_tree img {
    border: 0px solid;
    border-radius: 30px;

}

.image_section_tree span {}

.image_section_one span {}

.povid {
    position: relative;
    bottom: 28%;
    color: white;
    font-size: 22px;
    font-weight: 800;
    -webkit-position: relative;
    -webkit-bottom: 28%;
}

.services {
    max-width: 1600px;
    margin: auto;
    background-color: white;
}

@media (max-width: 860px) {
    .image_section_one img {
        width: 400px;
    }

    .text {
        margin-top: 0px;
    }

    .povid {
        font-size: 18px;
    }
}
</style>