<template>
    <div class="carusel">
        <div>
            <carousel :items-to-show="itemsToShow" :autoplay="4000" :wrapAround="true">
                <slide class="df" v-for="(image, index) in images" :key="index">
                    <img :src="image.path" :alt="image.alt">
                    <span class="text">{{ image.text }}</span>

                </slide>

                <template #addons>
                    <navigation />
                    <pagination />
                </template>
            </carousel>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    name: 'addSlider',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            isMobile: false,
            images: [
                { path: '/services/1o.png', text: 'Аквагрим', },
                { path: '/services/2o.png', text: 'Candy bar' },
                { path: '/services/3o.png', text: 'Фото и видеосъемка' },
                { path: '/services/4o.png', text: 'Сладкая вата' },
                { path: '/services/5o.png', text: 'Оформление' },
                { path: '/services/6o.png', text: 'Beauty bar' },

            ],
            itemsToShow: 3
        };
    },
    mounted() {

        window.addEventListener('resize', this.handleResize);

        this.handleResize();
    },
    beforeDestroy() {

        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            if (window.innerWidth < 600) {
                this.itemsToShow = 1;
            } else {
                this.itemsToShow = 3;
            }
        }
    }
}
</script>

<style>
.text {
    color: black;
    font-size: 25px !important;
}

.df {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.carusel {
    max-width: 1600px;
    margin: auto;
}

.carusel img {
    width: 500px;
  border: 0px solid;
  border-radius: 30px
}
@media (max-width: 600px) {
  .carusel img {
    padding: 10px;
  }
}
</style>
