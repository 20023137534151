<template>
  <div :class="['modal', { 'modal-large': isLargeScreen }]" @click.self="$emit('close')">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <img :src="item.path" alt="Image">
      <b>{{ item.text }}</b>
      <p v-html="item.description"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    isLargeScreen() {
      return window.innerWidth >= 800;
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
      document.body.classList.remove('modal-open');
    },
    openModal() {
      this.$emit('open');
      document.body.classList.add('modal-open');
    }
  },
  mounted() {
    this.openModal();
  },
  destroyed() {
    this.closeModal();
  }
};
</script>


<style scoped>
.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* полупрозрачный фон */
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* чтобы модальное окно было поверх всего */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  /* позиционируем содержимое относительно модального окна */
}

.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-content img {
  border: 0px solid;
  border-radius: 35px;
  width: 500px;
}

/* Стили для большого модального окна */
.modal-large .modal-content {
  width: 700px;
  max-width: 80%;
  /* ширина модального окна не более 80% от ширины экрана */
  height: auto;
}
</style>