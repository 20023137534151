<template>
    <form class="max-w-sm mx-auto " @submit.prevent="sendMessage">
        <div class="mb-5">
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Имя</label>
            <input v-model="name" type="text" id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="" required />
        </div>
        <label for="phone-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Номер
            телефона:</label>
        <div class="relative">
            <div class="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 18">
                    <path
                        d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                </svg>
            </div>
            <input v-model="phone" type="text" id="phone-input" aria-describedby="helper-text-explanation"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="+998" required />
        </div>

        <button type="submit"
            class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-m px-12 py-4 text-center me-2 mb-2 buts">Отправить!</button>
    </form>

</template>


<script>
import 'flowbite';
import axios from 'axios';
export default {
    name: "Form",
    data() {
        return {
            name: '',
            phone: ''
        }
    },
    methods: {
        async sendMessage() {
            try {
                const HTTP_API_TOKEN = '6810666825:AAGI0FcDeLafq570QwS538TtKEkjATOvd9Y';
                const CHAT_ID = '-1001669990267';
                // Check if all required fields are not empty
                if (this.name && this.phone) {
                    const MESSAGE_TEXT = `
Оппа, клиент :)
<b>Имя</b>: ${this.name}
<b>Номер телефона</b>: ${this.phone}
                `;

                    const apiUrl = `https://api.telegram.org/bot${HTTP_API_TOKEN}/sendMessage?chat_id=${CHAT_ID}&text=${encodeURIComponent(MESSAGE_TEXT)}&parse_mode=HTML`;

                    const response = await axios.get(apiUrl);
                    if (response.status === 200) {
                        // Alert window for successful message
                        this.name = '';
                        this.phone = '';
                        alert('Сообщение отправлено');
                    } else {
                        // Handle other HTTP response statuses if needed
                        alert('Error sending message. Unexpected status:');
                    }
                } else {
                    // Handle the case when some of the required fields are empty
                    alert('Заполните все поля!');
                }
            } catch (error) {
                // Handle the error, e.g., show an error message to the user.
                console.error('Error sending message:', error.message);
            }
        },
    }
};
</script>

<style>
.buts {
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    margin-top: 20px !important;
}
</style>