<template>
  <div class="home">
    <!-- first block -->
    <div class="home_info" v-if="!isMobile">
      <div class="info_left">
        <div class="left_img"><img src="../img/icon/logo.png" alt="image description"></div>
        <div class="left_text">
          <p class="text-4xl text-gray-900 font-extralight dark:text-white">Агенство детских праздников</p>
        </div>
      </div>
      <div class="info_right">
        <div class="right_text">
          <div class="city text-xl text-gray-900 font-extralight dark:text-white">г.Ташкент</div>
          <div class="phone text-2xl text-gray-900 font-extralight dark:text-white"><a
              href="tel:+998974118909">+998(97)411-89-09</a></div>
        </div>
        <div class="right_button">
          <a href="tel:+998974118909">
            <button type="button"
              class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-m px-10 py-4 text-center me-2 mb-2">Позвонить</button>
          </a>
        </div>
      </div>
    </div>
    <!-- first block -->

    <!-- clound -->
    <div class="cloud" v-if="!isMobile">
      <img class="h-auto max-w-full" src="../img/effect/cloud.png" alt="image description">
    </div>
    <!-- clound -->

    <!-- Form info -->
    <div class="main_content">
      <div class="main_logo"><img src="../img/icon/logo.png" alt="image description"></div>
      <div class="main_text">
        <p class="text-4xl text-gray-900 font-extralight dark:text-white">Организация детских праздников</p>
        <p class="text-4xl text-gray-900 font-extralight dark:text-white">в Ташкенте</p>
      </div>
      <div class="main_button">
        <button data-modal-target="authentication-modal" data-modal-toggle="authentication-modal" type="button"
          class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-m px-12 py-6 text-center me-2 mb-2">Оставить
          заявку</button>

      </div>
    </div>
    <Form />
    <!-- Form info -->

    <!-- clound -->
    <div class="cloud-reverb" v-if="!isMobile">
      <img class="h-auto max-w-full" src="../img/effect/cloud.png" alt="image description">
    </div>
    <!-- clound -->
  </div>

  <div class="second_sec">
    <!-- Second form -->
    <div class="body_section">
      <div class="section_two_text text-4xl text-gray-900 font-extralight dark:text-white">
        Почему выбирают именно нас?
      </div>
      <div class="box_image">
        <div class="box"><img src="../img/icon/why-1.png" alt=""><span>10 лет</span><span>в праздниках</span></div>
        <div class="box"><img src="../img/icon/why-2.png" alt=""><span>Все</span>
          <span>официально</span>
        </div>
        <div class="box"><img src="../img/icon/why-3.png" alt=""><span>Более</span> <span>150 костюмов героев</span>
        </div>
        <div class="box"><img src="../img/icon/why-4.png" alt=""><span>Интересные</span><span>сценарии</span></div>
      </div>
    </div>
    <!-- clound -->
    <div class="cloud" v-if="!isMobile">
      <img class="h-auto max-w-full" src="../img/effect/cloud.png" alt="image description">
    </div>
    <!-- clound -->
    <!-- Second form -->
  </div>

  <div class="third_sec">
    <Animators />
    <div class="main_button">
      <router-link to="/animator">
        <button type="button"
          class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-m px-12 py-6 text-center me-2 mb-2">Смотреть
          всех!</button>
      </router-link>
      <router-view />

    </div>
  </div>



  <!-- Slider -->

  <div class="shows">
    <!-- clound -->
    <div class="cloud" style="margin-top: 20px;" v-if="!isMobile">
      <img class="h-auto max-w-full" src="../img/effect/cloud.png" alt="image description">
    </div>
    <!-- clound -->
    <p class="text-4xl  text-white font-extralight dark:text-white text-center mrk">Выберите шоу на праздник</p>

    <Slider />

    <div class="main_button">
      <router-link to="/shows">
        <button type="button"
          class="buds text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-m px-12 py-6 text-center me-2 mb-2">Все
          шоу-программы!</button>
      </router-link>
      <router-view />

    </div>

    <!-- clound -->
    <div class="clouds-reverb" v-if="!isMobile">
      <img class="h-auto max-w-full" src="../img/effect/cloud.png" alt="image description">
    </div>
    <!-- clound -->

  </div>

  <!-- Slider -->


  <div class="addslider">

    <p class="text-4xl text-black font-extralight dark:text-white text-center kk">Добавьте к празднику</p>
    <p class="text-1xl text-black font-extralight dark:text-white text-center rk">Сделайте праздник еще ярче</p>


    <!-- addSlider -->
    <addSlider />
    <!-- addSlider -->

    <div class="main_button">
      <router-link to="/service">
        <button type="button"
          class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-m px-12 py-6 text-center me-2 mb-2">Все
          добавки!</button>
      </router-link>
      <router-view />

    </div>


  </div>

  <!-- review -->

  <div class="review">
    <!-- clound -->
    <div class="cloud" style="margin-top: 20px;" v-if="!isMobile">
      <img class="h-auto max-w-full" src="../img/effect/cloud.png" alt="image description">
    </div>
    <!-- clound -->
    <p class="hypc text-4xl text-black font-extralight dark:text-white text-center">Отзывы о нас!</p>

    <!-- reviewSlider -->
    <reviewSlider />
    <!-- reviewSlider -->
    <!-- clound -->
    <div class="clouds-reverb" v-if="!isMobile">
      <img class="h-auto max-w-full" src="../img/effect/cloud.png" alt="image description">
    </div>
    <!-- clound -->
  </div>

  <!-- review -->

  <!-- questions -->
  <div class="question">
    <div class="question_title">
      <p class="text-4xl text-black font-extralight dark:text-white text-center">Оcтались вопросы?</p>
      <p class="text-1xl text-black font-extralight dark:text-white text-center">Напишите или позвоните нам и мы с
        радостью
        ответим на них</p>
      <p class="text-1xl text-black font-extralight dark:text-white text-center">г.Ташкент</p>
      <br>
      <div class="text-4xl text-gray-900 font-extralight dark:text-white text-center"><a class="pointer"
          href="tel:+998974118909">+998(97)411-89-09</a></div>
      <br>
      <div class="text-3xl text-gray-900 font-extralight dark:text-white text-center"><a class="pointer"
          href="https://t.me/kidsparty_uz">Написать в телеграм</a></div>
    </div>

    <!-- Form -->
    <div class="forms">
      <formFooter />
    </div>


    <!-- clound -->
    <div class="clouds-reverb" v-if="!isMobile">
      <img class="h-auto max-w-full" src="../img/effect/cloud.png" alt="image description">
    </div>
    <!-- clound -->
  </div>
  <!-- Form -->

  <!-- questions -->

  <!-- Footer -->
  <Footer />

  <!-- Footer -->

</template>
<script>
// @ is an alias to /src
import Form from '../components/Form.vue';
import Animators from '../components/Animators.vue';
import Slider from '../components/Slider.vue';
import addSlider from '../components/addSlider.vue';
import reviewSlider from '../components/reviewSlider.vue';
import Footer from '../components/Footer.vue';
import formFooter from '../components/formFooter.vue';

import 'flowbite';

export default {
  name: 'HomeView',
  components: {
    Form,
    Animators,
    Slider,
    addSlider,
    reviewSlider,
    Footer,
    formFooter

  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    // Initialize isMobile on component mount
    this.checkScreenWidth();

    // Listen for window resize events
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeDestroy() {
    // Remove window resize event listener on component destruction
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.isMobile = window.innerWidth < 600; // Check if screen width is less than 430px
    }
  }
}
</script>

<style scoped>

.kk {
  margin-top: 40px
}

.rk {
  margin-bottom: 40px;
}

.mrk {
  margin: 40px;
}



.pointer {
  cursor: pointer !important;
}

.box img {
  width: 130px;
}

.section_two_text {
  text-align: center;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column
}
.box_image {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.main_button {
  display: flex;
  justify-content: center;
  margin: 40px 0px;
  font-size: 20px;
}

.main_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main_logo {
  margin-top: 15px;
}

.main_logo img {
  width: 200px;
}

.main_text p {
  color: white;
  text-align: center;
}

.home {
  width: 100%;
  /* Set width to viewport width */
  height: 100vh;
  /* Set height to viewport height */
  /* Set height as needed */
  background-image: url("/Users/ruslan/kidsparty/kidsflow/src/img/back/main.png");
  /* Specify the path to your image */
  background-size: cover;
  /* Adjust background size as needed */
  background-position: center;
  /* Adjust background position as needed */
}

.shows {
  width: 100vw;
  /* Set width to viewport width */
  /* Set height to viewport height */
  /* Set height as needed */
  background-image: url("/Users/ruslan/kidsparty/kidsflow/src/img/back/night.jpg");
  /* Specify the path to your image */
  background-size: cover;
  /* Adjust background size as needed */
  background-position: center;
  /* Adjust background position as needed */
}



.review {
  background: rgb(180, 154, 225);
  background: linear-gradient(180deg, rgba(180, 154, 225, 1) 0%, rgba(200, 160, 230, 1) 51%, rgba(240, 170, 240, 1) 100%);
}

.clouds-reverb {
  width: 100%;
  position: relative;
  top: 5px;
}

.clouds-reverb img {
  transform: rotate(180deg);
  width: 100%;
}

.cloud-reverb {
  width: 100%;
  position: absolute;
  bottom: -102px;
}

.cloud-reverb img {
  width: 100%;
  transform: rotate(180deg);
}

.cloud {
  position: relative;
  bottom: 1px;
}

.cloud img {
  width: 100%;
}

.home_info {
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 30px
}

.info_left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_img img {
  width: 100px;
}

.left_text {
  margin-left: 15px;
}

.info_right {
  display: flex;
}

.right_button {
  margin-left: 25px;
}

@media (max-width: 600px) {
  .home {
    width: 100vw;
    /* Set width to viewport width */
    height: 100vh;
    /* Set height to viewport height */
    /* Set height as needed */
    background-image: url("/Users/ruslan/kidsparty/kidsflow/src/img/back/mobile.png");
    /* Specify the path to your image */
    background-size: cover;
    /* Adjust background size as needed */
    background-position: center;
    /* Adjust background position as needed */
  }

  .main_logo {
    margin-top: 140px;
  }

  .max-w-sm {
    width: 80% !important;
    /* уменьшение ширины формы на 20% */
  }

  .question {
    padding: 50px 0px;
  }

  .review {
    padding: 50px 0px;
  }

  .hypc {
    margin-top: 30px;
  }

  .buds {
    margin-bottom: 50px
  }
  .box_image {
    flex-direction: column;
  }
  .box img {
    width: 200px;
  }
  .box span{
    font-size: 22px;
  }
}
</style>
