<template>
    <div class="carusel">
        <div>
            <carousel :items-to-show="itemsToShow" :autoplay="6000" :wrapAround="true">
                <slide v-for="(image, index) in images" :key="index">
                    <img :src="image.src" :alt="image.alt">
                </slide>

                <template #addons>
                    <navigation />
                    <pagination />
                </template>
            </carousel>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    name: 'addSlider',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            isMobile: false,
            images: [
                { src: '/review/2o.png', alt: 'Image 2' },
                { src: '/review/3o.png', alt: 'Image 3' },
                { src: '/review/4o.png', alt: 'Image 4' },
                { src: '/review/5o.png', alt: 'Image 5' },
                { src: '/review/6o.png', alt: 'Image 5' },
                { src: '/review/7o.png', alt: 'Image 7' },
                { src: '/review/8o.png', alt: 'Image 8' },
                { src: '/review/10o.png', alt: 'Image 10' },
                { src: '/review/11o.png', alt: 'Image 11' },
                { src: '/review/13o.png', alt: 'Image 13' },
            ],
            itemsToShow: 3 
        };
    },
    mounted() {

        window.addEventListener('resize', this.handleResize);

        this.handleResize();
    },
    beforeDestroy() {

        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            if (window.innerWidth < 600) {
                this.itemsToShow = 1;
            } else {
                this.itemsToShow = 3; 
            }
        }
    }
}
</script>

<style>
.carusel {
    max-width: 1600px;
    margin: auto;
}

.carusel img {
    width: 430px;
}

@media (max-width: 600px) {
    .carusel img {
    width: 330px;
} 
}
</style>
