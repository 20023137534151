import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AnimatorView from "../views/AnimatorView.vue";
import ServiceView from "../views/ServiceView.vue";
import ShowView from "../views/ShowView.vue";
import PageNotFound from '../views/PageNotFound.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: "/animator",
    name: "animator",
    component: AnimatorView,
  },
  {
    path: "/shows",
    name: "show",
    component: ShowView,
  },
  {
    path: "/service",
    name: "service",
    component: ServiceView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
})

router.afterEach((to, from) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
})

export default router
