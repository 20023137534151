<template>
    <p class="text-4xl text-gray-900 font-extralight dark:text-white text">Аниматоры</p>

    <div class="block_section">
        <div v-if="isWideScreen">
            <div class="block_section_three">
                <div class="image_section_tree" v-for="(item, index) in limitedImageItems" :key="index"
                    @click="openModal(item)">
                    <img :src="item.path" alt="Image">
                    <span>{{ item.text }}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="block_section_one">
                <div class="image_section_one" v-for="(item, index) in limitedImageItems" :key="index"
                    @click="openModal(item)">
                    <img :src="item.path" alt="Image">
                    <span>{{ item.text }}</span>
                </div>
            </div>
        </div>
        <!-- <Modal v-if="modalOpen" :item="selectedItem" @close="closeModal" /> -->
    </div>
</template>

<script>
import Modal from './Modal.vue';

export default {
    name: "Animator",
    components: {
        Modal
    },
    data() {
        return {
            imageItems: [
                { path: '../animators/10q.png', text: 'Леди баг и супер кот' },
                { path: '../animators/1q.png', text: 'Холодное сердце' },
                { path: '../animators/2q.png', text: 'Игра в кальмара ' },
                { path: '../animators/18q.png', text: 'Майнкрафт' },
                { path: '../animators/9q.png', text: 'Куклы ЛОЛ' },
                { path: '../animators/21q.png', text: 'Wednesday' },
                { path: '../animators/11q.png', text: 'Тик-Ток вечеринка' },
                { path: '../animators/8q.png', text: 'Супергерои' },
                { path: '../animators/19q.png', text: 'Пираты' },
                // другие объекты с путями изображений и текстом
            ],
            screenWidth: window.innerWidth,
            modalOpen: false,
            selectedItem: {}
        }
    },
    computed: {
        limitedImageItems() {
            return this.imageItems.slice(0, 9);
        },
        isWideScreen() {
            return this.screenWidth >= 800;
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth);
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
        openModal(item) {
            this.selectedItem = item;
            this.modalOpen = true;
        },
        closeModal() {
            this.modalOpen = false;
        }
    }
};
</script>

<style scoped>
span {
    font-size: 22px;
}
p {
    font-size: 35px !important;
}
.text {
    text-align: center;
    margin-top: 30px;
}

.block_section {
    text-align: center;
}

.block_section_three {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    /* Измените это значение по вашему желанию */
}

.block_section_one {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.image_section_tree,
.image_section_one {
    text-align: center;
    margin-bottom: 20px;
}

</style>